<template>
    <div class="w-5 h-5 rounded-full flex items-center justify-center" :class="colors">
        <Tooltip>
            <template v-slot:label><icon :name="icon" class="w-4 h-4 block" /></template>
            <template v-slot:default>{{status}}</template>
        </Tooltip>
    </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import Tooltip from "@/components/ui/Tooltip";
export default {
    components: { Icon,Tooltip },
    props: {
        status: {
            type: String,
            required: true
        }
    },
    computed: {
        icon() {
            let icon = '';

            switch (this.status) {
                case 'open' :
                    icon = 'tick';
                    break;
                case 'pending':
                    icon = 'alert';
                    break;
                case 'archived':
                    icon = 'bookmark';
                    break;
                default:
                    icon = 'pending';
                    break;
            }

            return icon;
        },
        colors() {
            let classes = [];

            switch (this.status) {
                case 'open' :
                    classes.push( 'text-green-600');
                    classes.push( 'bg-green-200');
                    break;
                case 'pending':
                    classes.push( 'text-yellow-600');
                    classes.push( 'bg-yellow-200');
                    break;
                case 'archived':
                    classes.push( 'text-purple-600');
                    classes.push( 'bg-purple-200');
                    break;
                default:
                    classes.push( 'text-teal-600');
                    classes.push( 'bg-teal-200');
                    break;
            }

            return classes.join(' ');
        }
    }
}
</script>