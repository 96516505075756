<template>
    <div class="mb-8">
        <!-- viewing & search -->
        <div class="flex justify-between items-center">
            <div>
                <guestcards-viewings v-model="filters"></guestcards-viewings>
            </div>
            <div>
                <input type="text" placeholder="search" class="border px-4 py-2" v-model="initialTextValue" @keyup="textHandler(initialTextValue)" autocomplete="off" />
                &nbsp;
                <icon name="filter" class="w-6 h-6 inline-block text-blue-500 cursor-pointer filter-icon" @click="isOpen = !isOpen"></icon>
            </div>
        </div>

        <!-- filters -->
        
        <div class="w-full bg-blue-100 mt-4 py-4 px-8 flex justify-between text-sm" v-if="isOpen">
             
            <div>
                <p class="font-medium select-none">status</p>
                <checkbox-input label="open" v-model="filters.statusOpen" class="mt-2"></checkbox-input>

                <checkbox-input label="archived" v-model="filters.statusArchived" class="mt-2"></checkbox-input>
            </div>
            <div>
                    <form-label># of Bedrooms</form-label>
                    <div class="flex flex-col">
                        <checkbox-input label="Studio" v-model="filters.bedroomStudio" class="mr-4"></checkbox-input>
                        <checkbox-input label="1 BR" v-model="filters.bedroomOne" class="mr-4"></checkbox-input>
                        <checkbox-input label="2 BR" v-model="filters.bedroomTwo"></checkbox-input>
                        <checkbox-input label="3 BR" v-model="filters.bedroomThree" class="mr-4"></checkbox-input>
                        <checkbox-input label="4 BR" v-model="filters.bedroomFour"></checkbox-input>
                    </div>
            </div>
            <div class="mx-5">
                <p class="font-medium select-none">created date range</p>
                <div class="flex justify-start items-center mt-2 ">

                    <input type="date"  ref="CreatedDateFrom" @change="checkCreatedDateClear"  class="form-input" v-model="createdDateFilter.startDate" />
                    <span class="block mx-4 select-none">to</span>
                     <input type="date" ref="CreatedDateTo"  @change="checkCreatedDateClear" :min="createdDateFilter.startDate ? createdDateFilter.startDate : null" class="form-input" v-model="createdDateFilter.endDate" />

                </div>
                <div class="borders">
                    <p class="font-medium select-none">move-in date</p>
                    <div class="flex justify-start items-center mt-2 ">

                        <input type="date" ref="MoveInDateFrom" @change="checkMoveinDateClear" class="form-input" v-model="moveInDateFilter.moveInDateFrom" />
                        <span class="block mx-4 select-none">to</span>
                        <input type="date" ref="MoveInDateTo" @change="checkMoveinDateClear"  :min="moveInDateFilter.moveInDateFrom ? moveInDateFilter.moveInDateFrom : null" class="form-input" v-model="moveInDateFilter.moveInDateTo" />

                    </div>
                </div>
            </div>
            <div>
                <p class="font-medium select-none">source</p>
                <div class="mt-2">
                    <dropdown
                        :options="referralOptions"
                        v-model="filters.source"
                        class="select-none"
                    ></dropdown>
                </div>
                <div class="borders">
                </div>
                 <p class="font-medium select-none">max rent budget</p>
                <div class="mt-2  ">
                     <input type="number" :step="250" :min="0" :max="6000" class=" form-input inline focus:outline-none w-32" v-model="filters.amount" />
                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Icon from "@/components/ui/Icon";

import {mapGetters} from "vuex";
import Dropdown from "@/components/ui/Dropdown";
import CheckboxInput from "@/components/ui/CheckboxInput";
import FormLabel from "@/components/ui/FormLabel";
import GuestcardsViewings from "@/components/guest_cards/index/GuestcardsViewings";

export default {
    components: { Icon, CheckboxInput, Dropdown, GuestcardsViewings,FormLabel },
    props: {
        value: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            currentDate:new Date().toISOString().split('T')[0],
            initialTextValue:"",
            timeout: null,
            filters: {
                bedroomStudio:false,
                bedroomOne:false,
                bedroomTwo:false,
                bedroomThree:false,
                bedroomFour:false,
                startDate:null,
                endDate:null,
                text:null,
                moveInDateFrom:null,
                moveInDateTo:null
            },
            createdDateFilter :{
                startDate:null,
                endDate:null
            },
            moveInDateFilter:{
                moveInDateFrom:null,
                moveInDateTo:null
            },
            oldCreateDateStart: null,
            oldCreateDateEnd: null,
            oldMoveinDateStart: null,
            oldMoveinDateEnd: null,
            isOpen: false,
            referralOptions: [],
            moveInDateTo : null,
            createddateto : null,
            disableCheck:false,
        }
    },
    emits: ['input'],
    watch: {
        createdDateFilter:{
            handler: function(value){
                if(value.endDate && value.startDate){
                    if((value.startDate != this.oldCreateDateStart) || (value.endDate != this.oldCreateDateEnd)){
                        if(moment(value.startDate).isAfter(value.endDate)){
                            this.createdDateFilter.endDate = null;
                            this.oldCreateDateEnd = null;
                            this.$refs.CreatedDateTo.focus();
                            this.oldCreateDateStart = value.startDate;
                        }

                        if( value.startDate && value.endDate){
                            this.filters.endDate = value.endDate;
                            this.filters.startDate = value.startDate;
                        }
                    }else{
                        this.filters.endDate = value.endDate;
                        this.filters.startDate = value.startDate;
                    }
                }
            },
            deep:true
        },
        moveInDateFilter:{
            handler: function(value){
                if(value.moveInDateTo && value.moveInDateFrom){
        if((value.moveInDateFrom != this.oldMoveinDateStart) || (value.moveInDateTo != this.oldMoveinDateEnd)){
                        if(moment(value.moveInDateFrom).isAfter(value.moveInDateTo)){
                            this.moveInDateFilter.moveInDateTo = null;
                            this.oldMoveinDateEnd = null;
                            this.$refs.MoveInDateTo.focus();
                            this.oldMoveinDateStart = value.moveInDateFrom;
                        }
                        if(value.moveInDateTo &&  value.moveInDateFrom){
                            this.oldMoveinDateStart = value.moveInDateFrom;
                            this.oldMoveinDateEnd = value.moveInDateTo;
                            this.filters.moveInDateTo = value.moveInDateTo;
                            this.filters.moveInDateFrom = value.moveInDateFrom;
                        }
                    }else{
                        this.filters.moveInDateTo = value.moveInDateTo;
                        this.filters.moveInDateFrom = value.moveInDateFrom;
                    } 
                }
                 this.$emit('input', this.filters );
            },
            deep:true
        },
        filters:{
            handler: function(val){
                    if(!val.endDate && !val.startDate){
                        this.createdDateFilter.startDate = null;
                        this.createdDateFilter.endDate = null;
                    }
                    if(!val.moveInDateFrom && !val.moveInDateTo){
                        this.moveInDateFilter.moveInDateFrom = null;
                        this.moveInDateFilter.moveInDateTo = null ;
                    }
                    this.$emit('input', val );
            },
            deep:true
        }
    },
    methods: {
        checkCreatedDateClear(e){
           
            if(!e.target.value){
                console.log("cleared!")
                this.filters.endDate = null
                this.filters.startDate = null
            }
        },
        checkMoveinDateClear(e){
           
            if(!e.target.value){
                console.log("cleared!")
                this.filters.moveInDateTo = null
                this.filters.moveInDateFrom = null
            }
        },
        textHandler(value){
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.filters.text = value 
            }, 1000);
        }
    },
    computed: {
        ...mapGetters( {
            getCommOptions: 'guest_cards/getCommOptions',
        })
    },
    created() {
        this.filters = Object.assign( {}, this.value);
        this.referralOptions = this.getCommOptions.lead_source;
    }
}
</script>

<style>
.borders{
    width: 95%;
    border-top: 1px solid #E6E6E6;
    margin-top: 10px;
}
</style>
