<template>
    <div class="flex justify-start items-center">
        <span class="font-medium text-black">viewing:</span>
        <div
            v-for="(keyword, index) in keywords"
            :key="index"
            class="bg-indigo-100 px-3 py-2 text-blue-700 font-bold mx-2 text-xs flex justify-between items-center"
        >
            <guestcard-status :status="keyword.text" v-if="['open', 'pending', 'archived'].includes(keyword.text)" class="mr-2"></guestcard-status>
            <div class="h-6 flex items-center">{{ keyword.text }}</div>

            <icon
                name="close"
                class="w-2 h-2 inline-block cursor-pointer text-blue-400 ml-2"
                @click="close(keyword)"
            >
            </icon>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Icon from "@/components/ui/Icon";
import GuestcardStatus from "@/components/guest_cards/index/GuestcardStatus";
import { referralOptions} from "@/utils/GuestCards";
export default {
    components: { Icon, GuestcardStatus },
    props: {
        value: {
            required: true,
            type: Object
        }
    },
    emits: ['input'],
    data: () => {
        return {
            filters: {},
            referralOptions: referralOptions,
        }
    },
    watch: {
        filters: {
            handler: function () {
                this.$emit('input', this.filters )
            },
            deep: true
        }
    },
    computed: {
        keywords() {
            let keywords = [];
            if(this.filters.statusOpen) keywords.push({ text: 'open', key: 'statusOpen', default: false });
            if(this.filters.statusPending) keywords.push({ text: 'pending', key: 'statusPending', default: false });
            if(this.filters.statusArchived) keywords.push({ text: 'archived', key: 'statusArchived', default: false });

            let created_dates = [];
            if(this.filters.startDate) created_dates.push( moment(this.filters.startDate).format('Y/M/D'));
            if(this.filters.endDate) created_dates.push( moment(this.filters.endDate).format('Y/M/D'));
            if(created_dates.length) keywords.push( { text: "created date : "+created_dates.join(' - '), key: 'createdDates', default: null } );

            let movein_dates = [];
            if(this.filters.moveInDateFrom) movein_dates.push( moment(this.filters.moveInDateFrom).format('Y/M/D'));
            if(this.filters.moveInDateTo) movein_dates.push(   moment(this.filters.moveInDateTo).format('Y/M/D'));
            if(movein_dates.length) keywords.push( { text: "move-in date : "+movein_dates.join(' - '), key: 'moveinDates', default: null } );
       
            if(this.filters.source) keywords.push({ text: "source : "+ this.referralOptions.find( (source) => source.key === this.filters.source  ).value, key: 'source', default: null });
            if(this.filters.bedroomStudio || this.filters.bedroomOne || this.filters.bedroomTwo || this.filters.bedroomThree || this.filters.bedroomFour){
                let bedrooms = []
                if(this.filters.bedroomStudio) bedrooms.push("Studio") 
                if(this.filters.bedroomOne) bedrooms.push("1BR") 
                if(this.filters.bedroomTwo) bedrooms.push("2BR") 
                if(this.filters.bedroomThree) bedrooms.push("3BR") 
                if(this.filters.bedroomFour) bedrooms.push("4BR") 
                        
                  
                keywords.push({ text: "bedroom : "+ bedrooms.join(', '),  key: 'bedrooms', default:null})
            }

            return keywords;
        }
    },
    methods: {
        close(keyword) {
            if(keyword.key === 'createdDates') {
                this.filters.startDate = null;
                this.filters.endDate   = null;
            }
            else if(keyword.key === 'moveinDates'){
                this.filters.moveInDateFrom = null
                this.filters.moveInDateTo   = null
            }
            else if(keyword.key == "bedrooms"){
                this.filters.bedroomStudio  = null
                this.filters.bedroomOne     = null
                this.filters.bedroomTwo     = null
                this.filters.bedroomThree   = null
                this.filters.bedroomFour    = null 
            }
            else {
                this.filters[keyword.key] = keyword.default;
            }
        }
    },
    created() {
        this.filters = Object.assign( {}, this.value);
    }
}
</script>
