<template>
    <div class="text-xs font-medium font-inter block select-none tracking-wide lowercase" :class="textColor">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        light: {
            type: Boolean ,
            required: false,
            default: false,
        }
    },
    computed: {
        textColor() {
            return this.light ? 'text-gray-400' : 'text-black';
        }
    }
}
</script>
