<template>
    <div>
        <guestcards-filter v-model="filters"></guestcards-filter>
        <guestcards-listing :filter="filters"></guestcards-listing>
    </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import GuestcardsFilter from "@/components/guest_cards/index/GuestcardsFilter";
import GuestcardsListing from "@/components/guest_cards/index/GuestcardsListing";

import {mapActions, mapGetters} from "vuex";
export default {
    name:'guestCards',
    components: { GuestcardsListing, GuestcardsFilter },
    mixins: [ ModalNavigation ],
    data: () => {
        return {
            guestCards: [],
            filters: {
                statusOpen: false,
                statusPending: false,
                statusArchived: false,
                startDate: null,
                endDate: null,
                source: null,
                text: '',
                bedroomStudio:false,
                bedroomOne:false,
                bedroomTwo:false,
                bedroomThree:false,
                bedroomFour:false,
                amount:null,
                moveInDateFrom:null,
                moveInDateTo:null
            }
        }
    },
    mounted(){
    },
    computed: {
        filterApplied() {
            return !!(this.filters.text || this.filters.source || this.statusesApplied.length || this.filters.startDate || this.filters.endDate);
        },
        statusesApplied() {
            let statuses = [];
            if(this.filters.statusOpen) statuses.push('open');
            if(this.filters.statusPending) statuses.push('pending');
            if(this.filters.statusArchived) statuses.push('archived');

            return statuses;
        },
        ...mapGetters({
            profile: 'auth/profile',
            community: 'auth/community',
            appsPermissions: 'auth/appsPermissions',
        }),
    },
    watch: {
        filters: {
            handler: function(newVal) {
                this.filters = newVal;
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            setCommOptions: 'guest_cards/setCommOptions',
            setUnits: 'guest_cards/setUnits',
        }),
        loadCommunityData(){
            this.$guestCardDataProvider
                .get('communityFormOptions')
                .then((response) => {
                    this.setCommOptions(response);
                    this.setUnits(response.units);
                })
                .catch();
        },
    },
    created() {
        this.loadCommunityData();
        this.guestCards = this.getGuestCards;
    }
}
</script>
