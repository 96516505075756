<template>

    <table class="min-w-full">

        <loader :loading="loading" :backdrop="true"/>

        <thead class="bg-white border-b border-black">
        <tr>
            <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
              <icon name="exclamationCircle" class="w-5 h-5 block"></icon>
            </th>
            <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
                created
            </th>
            <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
                name
            </th>
            <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
                email
            </th>
            <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
                phone
            </th>
            <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
                source
            </th>
             <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
                move in dates
            </th>
              <th class="px-3 py-2 text-left text-xs font-bold text-black lowercase tracking-wider">
                agent
            </th>
        </tr>
        </thead>
        <tbody>
            <tr
                v-for="(guestCard, index) in guestCards"
                :key="index"
                class="border-b border-gray-50 cursor-pointer text-black"
                :class="index % 2 !== 0 ? 'bg-gray-100 ' : 'bg-white '"
            >
                <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}">
                        <guestcard-status :status="guestCard.status_name" />
                    </modal-link>
                </td>
                <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}" class="block w-full">
                        {{  formatMoment(guestCard.created_at) }}
                    </modal-link>
                </td>
                <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}" class="block w-full">
                        {{  guestCard.first_name.charAt(0).toUpperCase() + guestCard.first_name.slice(1) + " " +guestCard.last_name.charAt(0).toUpperCase() + guestCard.last_name.slice(1) }}
                    </modal-link>
                </td>
                <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}" class="block w-full ">
                        <div class="ellipsis" :alt="guestCard.email">
                            {{  guestCard.email }}
                        </div>
                    </modal-link>
                </td>
                <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}" class="block w-full">
                        {{  guestCard.phone }}
                    </modal-link>
                </td>
                <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}" class="block w-full">
                        {{
                           guestCard.lead_source.display_name
                        }}
                    </modal-link>
                </td>
                 <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}" class="block w-full">
                        {{
                         guestCard.move_in_dates
                        }}
                    </modal-link>
                </td>
                 <td class="px-3 py-3 whitespace-nowrap text-sm">
                    <modal-link route="guest-cards.show" :params="{ guestCardId: guestCard.id}" class="block w-full">
                      {{getProfileDisplayName(guestCard.created_by_user_id)}}
                    </modal-link>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";
import Loader from '@/components/ui/Loader';
import ModalLink from "@/components/ui/modals/ModalLink";
import Icon from "@/components/ui/Icon"
import GuestcardStatus from "@/components/guest_cards/index/GuestcardStatus";
import AuthMixin from "@/components/auth/AuthMixin";

export default {
    components: { GuestcardStatus, ModalLink, Icon, Loader },
    mixins:[AuthMixin],
    props: {
        filter: {
            type: Object,
            required: true,
        }
    },
    data(){
        return {
            guestCards: [],
            queryFilters : this.filter,
            pagination:{
                offset:0,
                limit:20
            },
            loading:false, 
        }
    },
    watch : {
        filter:{
            handler: function(newVal) {
                this.queryFilters = newVal;
                this.loadGuestCards(newVal);
            },
            deep: true
        },
        leadSources: function(oldVal, newVal){
            if(oldVal.length != newVal.length){
                this.loadGuestCards()
            }
        }
     },
    created() {
        this.leadSources = this.getCommOptions.lead_source;
    },
    computed: {
        ...mapGetters( {
            profiles   : 'auth/profiles',
            getCommOptions: 'guest_cards/getCommOptions',
        })
    },
    methods: {
        loadGuestCards(filter_payload = null){
            this.loading = true
            let payload = {
                filter: JSON.stringify(filter_payload),
                pagination: JSON.stringify(this.pagination)
            }
            this.$guestCardDataProvider.get('guestCards',payload).then((response) => {
                    this.guestCards = response;
                    this.loading = false
            }).catch()
        },
        formatMoment(date){
            return moment(date).format('l');
        },
        getProfileDisplayName(id){
            let display_name = null;
            this.profiles.forEach(profile => {
                if (profile.profileId == id){
                    display_name = profile.firstName +" "+ profile.lastName;
                }
            });
            return display_name;
        }
    }
}
</script>
<style scoped>
 .ellipsis{
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis;
 }
</style>
