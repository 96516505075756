<template>
    <label class="flex justify-start items-center cursor-pointer">
        <div class="flex flex-shrink-0 justify-center items-center bg-white border rounded w-4 h-4 mr-2">
            <input type="checkbox" class="opacity-0 absolute" v-model="isChecked" v-bind="$attrs">
            <span class="inline-block pointer-events-none w-3 h-3 text-blue-500" :class="tickClass">
                <icon name="tick"></icon>
            </span>
        </div>
        <div class="select-none" v-if="label" v-html="label"></div>
    </label>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
    components: { Icon },
    inheritAttrs: false,
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: ''
        }
    },
    emits: ['input'],
    data: () => {
        return {
            isChecked: false,
        }
    },
    computed: {
        tickClass() {
            return this.isChecked ? ' inherit': ' hidden';
        }
    },
    watch: {
        isChecked: {
            handler: function() {
                this.$emit('input', this.isChecked);
            },
            immediate: true
        },
        value: {
            handler: function() {
                this.isChecked = this.value;
            },
            immediate: true
        }
    }
}
</script>
